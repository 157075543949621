import React, { useState } from "react";
import { motion, useInView } from "framer-motion";
import CurvyBackground from "../components/ui/Curvy_line";
import ReactiveParticles from "../components/ui/Reactive_particles";
const AnimatedSection = ({ children, className }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });

  return (
    <motion.div
      ref={ref}
      className={className}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      exit="hidden"
      variants={sectionVariants}
    >
      {children}
    </motion.div>
  );
};
const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const Compiler = ({ Compilerref }) => {
  const [isCompile, setisCompile] = useState(false);
  return (
    <motion.div
      style={{
        backgroundColor: "#111111",
      }}
      ref={Compilerref}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="relative  w-full h-screen flex flex-col text-gray-100 font-poppins items-center"
    >
      <div className="absolute inset-0">
        <ReactiveParticles color="#00ffff" compile={isCompile} />
      </div>
      <div className=" flex items-center space-x-3 justify-center h-full ">
        <motion.img
          src="./logo.png"
          className="w-4 xs:w-6 sm:w-8 z-10 lg:w-10 xl:w-12"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        />
        <motion.div
          className="text-xl xs:text-2xl sm:text-3xl z-10 lg:text-4xl xl:text-5xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          Compiler
        </motion.div>
      </div>
      <motion.div
        className="absolute flex cursor-pointer items-end justify-center h-full pb-10   z-30 text-2xl text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
      >
        <div className="">Coming soon...</div>
      </motion.div>
    </motion.div>
  );
};

export default Compiler;
