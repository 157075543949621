import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { GUI } from "lil-gui";

// Note: You'll need to create these shader files
import fragment from "./shader/fragment.js";
import vertex from "./shader/vertex.js";

const CurvyBackground = ({ color, rotation, position, debug = false }) => {
  const containerRef = useRef(null);
  const sketchRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const sketch = new CurvyBackgroundImpl({
      dom: container,
      rotation: rotation,
      position: position,
      color: color,
      debug: debug,
    });
    sketchRef.current = sketch;

    return () => {
      if (sketchRef.current) {
        sketchRef.current.destroy();
      }
    };
  }, []);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
};

class CurvyBackgroundImpl {
  constructor(options) {
    this.scene = new THREE.Scene();
    this.container = options.dom;
    this.debug = options.debug;
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x111111, 1);
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      (this.height * 20) / 65,
      this.width / this.height,
      0.1,
      1000
    );
    console.log(options.position);
    this.camera.position.set(
      options.position[0],
      options.position[1],
      options.position[2]
    );
    this.camera.rotation.set(
      options.rotation[0],
      options.rotation[1],
      options.rotation[2]
    );

    this.time = 0;
    this.isPlaying = true;

    this.settings = {
      curveCount: 45,
      curveAmplitude: 1,
      curveFrequency: 0.2,
      curveChaos: 0,
      energySpeed: 0.05,
      energyColor: options.color,
    };
    if (this.debug) {
      this.setupGUI();
    }

    this.addObjects();
    this.resize();
    this.setupResize();
    this.render();
  }

  setupGUI() {
    this.gui = new GUI();
    this.gui
      .add(this.settings, "curveCount", 10, 100, 1)
      .onChange(() => this.regenerateCurves());
    this.gui
      .add(this.settings, "curveAmplitude", 0.1, 1, 0.05)
      .onChange(() => this.updateCurves());
    this.gui
      .add(this.settings, "curveFrequency", 0.1, 2, 0.1)
      .onChange(() => this.updateCurves());
    this.gui
      .add(this.settings, "curveChaos", 0, 0.5, 0.05)
      .onChange(() => this.updateCurves());
    this.gui.add(this.settings, "energySpeed", 0.1, 2, 0.1);
    this.gui
      .addColor(this.settings, "energyColor")
      .onChange(() => this.updateMaterial());
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    this.curves = [];
    this.regenerateCurves();
    this.updateMaterial();
  }

  regenerateCurves() {
    this.curves.forEach((curve) => this.scene.remove(curve));
    this.curves = [];

    for (let i = 0; i < this.settings.curveCount; i++) {
      const curve = this.createCurve(i);
      this.curves.push(curve);
      this.scene.add(curve);
    }
  }

  createCurve(index) {
    const points = [];
    const segmentCount = 400; // Increase segment count for a longer curve
    const xOffset = (index / (this.settings.curveCount - 1)) * 2 - 1;

    for (let i = 0; i <= segmentCount; i++) {
      const x = (i / segmentCount) * 4 - 2; // Normalized x from -2 to 2
      const y = this.settings.curveAmplitude * (x * x) + xOffset; // Parabolic equation
      const chaos = (Math.random() - 0.5) * this.settings.curveChaos;
      points.push(new THREE.Vector3(x, y + chaos, 0));
    }

    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const material = new THREE.LineBasicMaterial({
      color: this.settings.energyColor,
      transparent: true,
      opacity: 0.5,
    });
    return new THREE.Line(geometry, material);
  }

  updateCurves() {
    this.curves.forEach((curve, index) => {
      const points = curve.geometry.attributes.position.array;
      const xOffset = (index / (this.settings.curveCount - 1)) * 2 - 1;

      for (let i = 0; i < points.length; i += 3) {
        const x = (i / 3 / 200) * 4 - 2; // Normalized x from -2 to 2
        const y = this.settings.curveAmplitude * (x * x) + xOffset; // Parabolic equation
        const chaos = (Math.random() - 0.5) * this.settings.curveChaos;
        points[i + 1] = y + chaos;
      }

      curve.geometry.attributes.position.needsUpdate = true;
    });
  }

  updateMaterial() {
    this.curves.forEach((curve) => {
      curve.material.color.setStyle(this.settings.energyColor);
    });
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.05;

    // Animate energy flowing through curves
    this.curves.forEach((curve) => {
      const positions = curve.geometry.attributes.position.array;
      for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const energyOffset =
          Math.sin((x + this.time * this.settings.energySpeed) * Math.PI * 2) *
          0.05;
        positions[i + 2] = energyOffset;
      }
      curve.geometry.attributes.position.needsUpdate = true;
    });

    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }

  destroy() {
    this.isPlaying = false;
    this.renderer.dispose();
    if (this.debug) {
      this.gui.destroy();
    }

    window.removeEventListener("resize", this.resize);
    while (this.container.firstChild) {
      this.container.removeChild(this.container.firstChild);
    }
  }
}

export default CurvyBackground;
