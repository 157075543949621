import React, { useState } from "react";
import { motion } from "framer-motion";
import CurvyBackground from "../components/ui/Curvy_line";
// import Sketch from "../components/ui/Distort_pixel";
// import { BackgroundGradientAnimation } from "../components/ui/background-gradient-animation";

const Hero = () => {
  const [isCrossGLAnimated, setIsCrossGLAnimated] = useState(false);

  const variants = {
    hidden: { x: 0, opacity: 0 },
    visible: {
      x: "-210%",
      opacity: 1,
      transition: {
        opacity: { delay: 1, duration: 1 },
        delay: 1.3,
        type: "tween",
        duration: 0.75,
        when: "beforeChildren",
      },
    },
  };

  const letterVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 1.7,
        type: "tween",
        duration: 0.7,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.5,
        duration: 0.8,
      },
    }),
  };

  return (
    <div className="relative font-poppins w-full h-screen">
      {/* <div className="absolute inset-0">
        <Sketch imageUrl="./img/2.jpg" />
      </div> */}
      <div className="absolute inset-0">
        <CurvyBackground
          rotation={[-Math.PI / 2, 0, Math.PI / 5]}
          position={[0, 0, 0]}
          color="#00ffff"
        />
      </div>
      <div className="relative flex flex-col items-center justify-center w-full h-full text-white">
        <motion.div className="flex flex-row items-center justify-center w-screen space-x-16 sm:space-x-10 lg:space-x-24 xl:space-x-24">
          <motion.img
            src="./logo.png"
            variants={variants}
            className="w-16 xs:w-16 sm:w-20 lg:w-24 xl:w-24 absolute"
            initial="hidden"
            animate="visible"
          />
          <motion.div className="">
            <motion.span
              className="text-6xl xs:text-5xl sm:text-6xl lg:text-8xl xl:text-8xl font-semibold"
              variants={letterVariants}
              initial="hidden"
              animate="visible"
              style={{ height: "100%" }}
              onAnimationComplete={() => setIsCrossGLAnimated(true)}
            >
              CrossGL
            </motion.span>
          </motion.div>
        </motion.div>
        {isCrossGLAnimated && (
          <motion.div
            className="w-screen flex items-center justify-center mt-4 sm:mt-4 lg:mt-8 xl:mt-8"
            initial="hidden"
            animate="visible"
          >
            <motion.div
              className="flex space-x-4"
              initial="hidden"
              animate="visible"
            >
              <motion.span
                variants={textVariants}
                custom={0}
                className="text-gray-200 font-base text-sm sm:text-lg lg:text-xl xl:text-2xl"
              >
                One Platform.
              </motion.span>
              <motion.span
                variants={textVariants}
                custom={1}
                className="text-gray-200 font-base text-sm sm:text-lg lg:text-xl xl:text-2xl"
              >
                Any Hardware.
              </motion.span>
              <motion.span
                variants={textVariants}
                custom={2}
                className="text-gray-200 font-base text-sm sm:text-lg lg:text-xl xl:text-2xl"
              >
                Infinite Possibilities.
              </motion.span>
            </motion.div>
          </motion.div>
        )}
      </div>
      <div
        className="absolute bottom-0 left-0 right-0 h-20"
        style={{
          background:
            "linear-gradient(to top, rgba(17, 17, 17, 1), rgba(17, 17, 17, 0))",
        }}
      />
    </div>
  );
};

export default Hero;