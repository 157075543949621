import React, { useState } from "react";
import { motion } from "framer-motion";
import CurvyBackground from "../components/ui/Curvy_line";
// import { FloatingNav } from "../components/ui/top_navbar";
// import { redirect } from "react-router-dom";

const Contact = () => {
  const [occupation, setOccupation] = useState("");
  const [otherOccupation, setOtherOccupation] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [institution, setInstitution] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
    if (event.target.value !== "Other") {
      setOtherOccupation("");
    }
    setCompany("");
    setPosition("");
    setInstitution("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      country: event.target.country.value,
      occupation: occupation === "Other" ? otherOccupation : occupation,
      company:
        occupation === "Engineer" ||
        occupation === "VC" ||
        occupation === "Enterprise" ||
        occupation === "Other"
          ? event.target.company.value
          : "",
      position:
        occupation === "Engineer" ||
        occupation === "VC" ||
        occupation === "Enterprise" ||
        occupation === "Other"
          ? event.target.position.value
          : "",
      institution:
        occupation === "Student" || occupation === "Educator"
          ? event.target.institution.value
          : "",
      message: event.target.message.value,
    };

    console.log("Form Data:", formData); // Debug: Log form data

    const requestOptions = {
      redirect: "follow",
      method: "POST",
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwy6U8PIOg3bacG6WgujUc2lg2I3d85AaV6-I6MM9TnXHHzk08Gmckifj1B2CFsrPRq/exec",
        requestOptions
      );
      const data = await response.json();
      console.log("Response Data:", data); // Debug: Log response data
      if (data.result === "success") {
        setSubmitted(true);
      } else {
        console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="relative font-poppins w-full h-screen bg-gray-900">
      <div className="absolute inset-0">
        <CurvyBackground
          rotation={[-Math.PI / 2, 0, Math.PI / 5]}
          position={[0, 0, 0]}
          color="#00ffff"
        />
      </div>
      <div className="relative flex flex-col md:flex-row items-center justify-center w-full h-full text-white">
        <div className="flex flex-col items-center justify-center md:items-center md:w-1/2 p-6">
          <motion.h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold mb-4 sm:mb-6 lg:mb-8 text-center">
            Keep in touch!
          </motion.h1>
          <motion.div className="flex flex-wrap justify-around w-full mb-4 sm:mb-6 lg:mb-8">
            {[
              {
                href: "https://discord.com/invite/uyRQKXhcyW",
                img: "./img/disc.webp",
                alt: "Discord",
              },
              { href: "https://x.com/crossGL_", img: "./img/x.webp", alt: "X" },
              {
                href: "https://www.youtube.com/@CrossGL-i9q",
                img: "./img/yt.png",
                alt: "YouTube",
              },
              {
                href: "https://www.linkedin.com/company/crossgl/",
                img: "./img/linkedin.webp",
                alt: "LinkedIn",
              },
            ].map((social) => (
              <a
                key={social.alt}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-gray-300 m-2"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={social.img}
                  alt={social.alt}
                  className="mb-2 w-8 h-8 sm:w-10 sm:h-10"
                />
                {social.alt}
              </a>
            ))}
          </motion.div>
        </div>

        {submitted ? (
          <div className="w-full max-w-2xl bg-gray-800 p-8 shadow-lg md:w-1/2 text-center">
            <h2 className="text-2xl text-white">Thanks!</h2>
            <p className="text-gray-300">
              Your message has been successfully sent. We will get back to you
              soon.
            </p>
          </div>
        ) : (
          <motion.form
            className="w-full max-w-2xl bg-gray-800 p-8 shadow-lg md:w-1/2"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-2 sm:grid-cols-2 gap-6 mb-6">
              <input
                type="text"
                name="firstName"
                placeholder="First Name*"
                className="border p-2 bg-gray-700 text-white"
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name*"
                className="border p-2 bg-gray-700 text-white"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email Address*"
                className="border p-2 bg-gray-700 text-white col-span-2"
                required
              />
              <input
                type="text"
                name="country"
                placeholder="Country/Region*"
                className="border p-2 bg-gray-700 text-white col-span-2"
                required
              />

              <div className="col-span-2">
                <label className="block text-gray-300 mb-2">Occupation</label>
                <select
                  name="occupation"
                  value={occupation}
                  onChange={handleOccupationChange}
                  className="border p-2 bg-gray-700 text-white w-full"
                  required
                >
                  <option value="" disabled>
                    Select your occupation
                  </option>
                  <option value="Engineer">Engineer</option>
                  <option value="Enterprise">Enterprise</option>
                  <option value="VC">VC</option>
                  <option value="Student">Student</option>
                  <option value="Educator">Educator</option>
                  <option value="Other">Other</option>
                </select>
                {occupation === "Other" && (
                  <input
                    type="text"
                    placeholder="Please specify"
                    value={otherOccupation}
                    onChange={(e) => setOtherOccupation(e.target.value)}
                    className="border p-2 bg-gray-700 text-white mt-2 w-full"
                    required
                  />
                )}
                {(occupation === "Engineer" ||
                  occupation === "VC" ||
                  occupation === "Other" ||
                  occupation === "Enterprise") && (
                  <>
                    <input
                      type="text"
                      name="company"
                      placeholder="Company*"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      className="border p-2 bg-gray-700 text-white mt-2 w-full"
                      required
                    />
                    <input
                      type="text"
                      name="position"
                      placeholder="Position*"
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      className="border p-2 bg-gray-700 text-white mt-2 w-full"
                      required
                    />
                  </>
                )}
                {(occupation === "Student" || occupation === "Educator") && (
                  <input
                    type="text"
                    name="institution"
                    placeholder="Institution*"
                    value={institution}
                    onChange={(e) => setInstitution(e.target.value)}
                    className="border p-2 bg-gray-700 text-white mt-2 w-full"
                    required
                  />
                )}
              </div>
              <textarea
                name="message"
                placeholder="Message*"
                className="border p-2 bg-gray-700 text-white col-span-2 h-32"
                required
              />
            </div>
            <div className="mb-6 text-gray-300">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" required />I agree to
                the terms and conditions
              </label>
            </div>
            <button
              type="submit"
              className="bg-red-500 text-white py-2 px-4 w-full"
            >
              Submit
            </button>
          </motion.form>
        )}
      </div>
      <div
        className="absolute bottom-0 left-0 right-0 h-20"
        style={{
          background:
            "linear-gradient(to top, rgba(17, 17, 17, 1), rgba(17, 17, 17, 0))",
        }}
      />
    </div>
  );
};

export default Contact;
