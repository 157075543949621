const bashInstallCommand = `
pip install crosstl
`;

const pythonCodeExample = `
import crosstl

# Translate some random HLSL code to CrossGL code.
crossgl_code = crosstl.translate('shader.hlsl', backend='cgl', save_shader='./shader.cgl')

# Now translate to any backend you want!

# Translate to Metal
metal_code = crosstl.translate('shader.cgl', backend='metal')

# Translate to DirectX (HLSL)
hlsl_code = crosstl.translate('shader.cgl', backend='directx')

# Translate to OpenGL
opengl_code = crosstl.translate('shader.cgl', backend='opengl')
`;

const blogData = [
  {
    id: 1,
    title: "Bridging the Gaps in Graphics Development with CrossGL",
    author: {
      name: "Vaatsalya Shrivastava",
      title: "Founder & CEO, CrossGL",
      image: "/img/author_0.png",
      bio: "Vaatsalya Shrivastava is a visionary in the field of graphics development, dedicated to creating seamless, cross-platform tools that empower developers to focus on creativity. With a deep understanding of the challenges faced by the graphics community, he leads the CrossGL team in building innovative solutions that bridge the gaps in the fragmented graphics landscape.",
    },
    image: "/img/banner.png",
    readTime: "5 min read",
    date: "August 16, 2024",
    content: `
Imagine being a graphics developer, eagerly working on your latest project, only to find out that OpenGL is no longer supported on macOS. Developers were suddenly forced to rethink their strategies, juggling the limitations and compatibility of various graphics APIs. This frustrating fragmentation of the graphics ecosystem left many developers searching for a better way.

At CrossGL, we knew there had to be a solution—a way to unify the fragmented landscape of graphics development. Our vision? A seamless, operating system-agnostic environment that simplifies the complexities of graphics programming. With this vision in mind, we built CrossGL, an innovative development ecosystem designed to bridge the gaps between different graphics APIs and empower developers to focus on their creative work.

The graphics development landscape is notoriously fragmented, often presenting a barrier to innovation. Developers should be free to create stunning visuals without being bogged down by technical limitations. That's where CrossGL comes in.

CrossGL is designed to streamline the graphics development process, making it easier for developers to write once and deploy everywhere. Our ecosystem includes two key components: CrossTL and CrossVL.

CrossTL, our universal translator available as a Python package on PyPI, allows developers to code in one graphics language and seamlessly translate it into any other. Whether you're working with OpenGL, Metal, DirectX, Vulkan, or even Mojo, CrossTL eliminates the hassle of maintaining multiple codebases, enabling you to focus on a single, unified codebase that works across all platforms.

To prove that this can indeed be done, all you need is a system that understands graphics (any modern system at this point). Run the following pieces of code:

[CODE_BASH_INSTALL]

[CODE_PYTHON_EXAMPLE]

CrossVL, our in-built visualizer, takes your development experience to the next level. Traditionally, developers have been limited to specific platforms like DirectX for rendering and testing their graphics code. But with CrossVL, you can visualize your code in real-time, no matter which graphics API you're using. This not only speeds up development but also ensures that your graphics look and perform consistently across all platforms.

[IMAGE:/img/image.jpg; CrossVL visualizer showing cube across different platforms]

CrossGL is not just solving the problems of today—we're building the future of graphics development. By eliminating the need for multiple codebases and providing powerful tools for translation and visualization, we're empowering developers to create stunning graphics without the usual limitations.

At CrossGL, we’re not stopping at translation and visualization. We're also working on an ambitious project: the CrossGL Compiler. This compiler will allow developers to write code in our unified CrossGL language and compile it directly into platform-specific binaries, completely eliminating the need for additional translation layers. This innovation will not only reduce development time but also improve performance, making CrossGL a true game-changer in the world of graphics programming.

Ready to experience the power of CrossGL? Download CrossTL from PyPI today and start transforming your graphics pipeline. And don't forget to try out CrossVL to see your shaders in action, no matter which graphics API you're using.

Let's build something amazing together. If our vision resonates with you, or if you believe in what we're building, consider giving us a star on GitHub. Your support helps us continue to innovate and bring powerful tools to developers everywhere. Join our journey and be a part of something transformative.
    `,
  },
];

export default blogData;
export { bashInstallCommand, pythonCodeExample };

