import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const FeatureCard = ({ title, description }) => (
  <article className="hover:animate-background rounded-xl bg-gradient-to-r from-teal-500 to-blue-700 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
    <div className="rounded-[10px] bg-gray-900 p-6 grid grid-rows-[auto_1fr] text-white h-full">
      <h3 className="text-lg font-medium">{title}</h3>
      <p className="mt-4 text-gray-400">{description}</p>
    </div>
  </article>
);

const DysonPage = () => {
  const videoRef = useRef(null);
  const videoCompareRef1 = useRef(null);
  const videoCompareRef2 = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const playBothVideos = () => {
    const video1 = videoCompareRef1.current;
    const video2 = videoCompareRef2.current;

    if (video1 && video2) {
      video2.currentTime = video1.currentTime;

      video1.play();
      video2.play();
    }
  };

  const pauseBothVideos = () => {
    const video1 = videoCompareRef1.current;
    const video2 = videoCompareRef2.current;

    if (video1 && video2) {
      video1.pause();
      video2.pause();
    }
  };

  useEffect(() => {
    const observerCallback = (entry) => {
      if (entry.isIntersecting) {
        playBothVideos();
      } else {
        pauseBothVideos();
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => observerCallback(entry),
      { threshold: 0.5 }
    );

    if (videoCompareRef1.current) observer.observe(videoCompareRef1.current);

    return () => {
      if (videoCompareRef1.current)
        observer.unobserve(videoCompareRef1.current);
    };
  }, []);

  return (
    <div className="dyson-page bg-black text-white">
      <header className="header-section text-center pt-32 pb-20 bg-gradient-to-r from-black via-decoration-[#ff233c] to-black text-red-600">
        <motion.h1
          className="text-6xl font-bold"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Dyson
        </motion.h1>
        <motion.p
          className="mt-6 text-xl text-gray-400 px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          Intelligent, Dynamic Routing for Computational Efficiency
        </motion.p>
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <ScrollLink
            to="key-features"
            smooth={true}
            className="scroll-down mt-16 inline-block font-medium bg-white text-black py-2 px-4 rounded-full relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-red-600 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
          >
            <span className="relative z-10">Learn More</span>
          </ScrollLink>
        </motion.h1>
      </header>

      <section
        id="key-features"
        className="key-features py-10 bg-black container mx-auto px-4"
      >
        <h2 className="text-center text-4xl font-bold mb-4 text-blue-500 relative flex items-center justify-center">
          <span className="flex-grow max-w-lg border-t border-red-600"></span>
          <span className="mx-4 text-teal-500">Key Features</span>
          <span className="flex-grow max-w-lg border-t border-red-600"></span>
        </h2>
        <div className="features-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-20">
          <FeatureCard
            title="LLM-Powered Decision Making"
            description="Dyson uses a state-of-the-art LLM (MOE) to evaluate complexity, precision, and the nature of each input."
          />
          <FeatureCard
            title="Modular SDK with Efficient Mathematical Kernels"
            description="A comprehensive library of optimized mathematical functions, implemented with maximum efficiency."
          />
          <FeatureCard
            title="Integration with Existing Workflows"
            description="Dyson’s modular SDK fits into existing frameworks like Python, C++, and Bash."
          />
        </div>
      </section>

      <section className="video-section py-10 bg-black text-white container mx-auto px-4">
        <div className="video-container flex p-10 justify-center border md:border-1 border-red-600 rounded-lg max-w-md md:max-w-lg lg:max-w-4xl mx-auto">
          <motion.video
            ref={videoRef}
            className="w-full"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            muted
            loop
            controls={false}
            preload="auto"
            src={require("../assets/video/dyson_animation_1.mp4")}
          />
        </div>

        <h2 className="text-center text-4xl mb-12 text-blue-500 relative flex items-center justify-center">
          <h2 className="text-center mx-4 mt-16 text-gray-400 text-lg max-w-4xl leading-relaxed">
            Dyson supports a broad spectrum of hardware, from traditional CPUs
            and GPUs to specialized accelerators like TPUs and FPGAs, as well as
            emerging technologies like quantum computers.
          </h2>
        </h2>
      </section>

      <section className="compare-section py-10 bg-black container mx-auto px-4">
        <h2 className="text-center text-4xl font-bold mb-12 relative flex items-center justify-center">
          <span className="flex-grow max-w-40 border-t border-red-600"></span>
          <h2 className="text-center text-4xl mx-5">
            With <span className="text-blue-400">Dyson</span>, never worry about{" "}
            <span className="text-blue-400">what hardware to use</span> !
          </h2>
          <span className="flex-grow max-w-40 border-t border-red-600"></span>
        </h2>

        <div className="videos-container flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-8 md:space-y-0 mt-20 md:mt-40">
          <motion.video
            ref={videoCompareRef1}
            className="w-full max-w-lg md:max-w-2xl rounded-lg"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            muted
            loop
            controls={false}
            preload="auto"
            src={require("../assets/video/compare_anim_1.webm")}
          />
          <motion.video
            ref={videoCompareRef2}
            className="w-full max-w-lg md:max-w-2xl rounded-lg"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            muted
            loop
            controls={false}
            preload="auto"
            src={require("../assets/video/compare_anim_2.webm")}
          />
        </div>

        <h2 className="text-center text-4xl mb-12 text-blue-500 relative flex items-center justify-center">
          <h2 className="text-center mx-4 mt-16 text-gray-400 text-lg max-w-4xl leading-relaxed">
            Simply provide your problem to Dyson, and let it dynamically select
            the best available hardware for you.
          </h2>
        </h2>
      </section>

      <section className="pricing-section text-center py-10 bg-black text-white">
        <motion.h1
          className="text-6xl font-bold mb-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
         <h2 className="text-center text-4xl font-bold mb-12 relative flex items-center justify-center">
          <span className="flex-grow max-w-[430px] border-t border-red-600"></span>
          <h2 className="text-center text-4xl mx-5">
            <span className="text-blue-400">Ready to Get Started</span> ?
          </h2>
          <span className="flex-grow max-w-[430px] border-t border-red-600"></span>
        </h2>
        </motion.h1>

        <motion.div
          className="mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        >
          <Link
            to="/pricing"
            className="scroll-down mt-10 inline-block font-medium bg-white text-black py-2 px-6 rounded-full relative overflow-hidden shadow before:absolute before:left-0 before:top-0 before:bg-red-600 before:pointer-events-none before:w-full before:h-full before:scale-x-0 hover:before:scale-x-100 before:duration-500 before:origin-right hover:before:origin-left before:transition-transform before:transition-all ease-in-out before:z-0"
          >
            <span className="relative z-10">View Pricing</span>
          </Link>
        </motion.div>
      </section>
    </div>
  );
};

export default DysonPage;
