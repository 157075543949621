import React, {
  useState,
  useRef,
  // useCallback,
  useMemo,
  // useEffect,
} from "react";
import CurvyBackground from "../components/ui/Curvy_line";
import { motion, useInView } from "framer-motion";
import {
  // GlobalCanvas,
  ScrollScene,
  UseCanvas,
  // SmoothScrollbar,
} from "@14islands/r3f-scroll-rig";
import { shaderMaterial } from "@react-three/drei";
import * as THREE from "three";
// import vertex from "../components/ui/shader/vertex";
import { useFrame, extend } from "@react-three/fiber";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
const shaders = {
  Vulkan: {
    vertex: `#version 450
layout(location = 0) in vec2 uv;
layout(location = 1) in vec3 position;

layout(location = 0) out vec3 vUv;

void main() {
  vUv = vec3(uv, 1.0);
  gl_Position = vec4(position, 1.0);
}`,
    fragment: `#version 450
layout(location = 0) in vec3 vUv;
layout(location = 0) out vec4 outColor;

void main() {
  vec3 color = mix(vec3(1.0, 0.0, 0.0), vec3(0.68, 0.85, 0.9), vUv.y);
  outColor = vec4(color, 1.0);
}`,
  },

  OpenGL: {
    vertex: `#version 450
layout(location = 0) in vec2 uv;
layout(location = 1) in vec3 position;
out vec3 vUv;

void main() {
  vUv = vec3(uv, 1.0);
  gl_Position = vec4(position, 1.0);
}`,
    fragment: `#version 450
in vec3 vUv;
layout(location = 0) out vec4 outColor;

void main() {
  vec3 color = mix(vec3(1.0, 0.0, 0.0), vec3(0.68, 0.85, 0.9), vUv.y);
  outColor = vec4(color, 1.0);
}`,
  },
  Metal: {
    vertex: `#include <metal_stdlib>
using namespace metal;

struct VSInput {
  float3 position [[attribute(0)]];
  float2 uv [[attribute(1)]];
};

struct VSOutput {
  float4 position [[position]];
  float3 vUv;
};

vertex VSOutput vertex_main(VSInput in [[stage_in]]) {
  VSOutput out;
  out.vUv = float3(in.uv, 1.0);
  out.position = float4(in.position, 1.0);
  return out;
}`,
    fragment: `#include <metal_stdlib>
using namespace metal;

struct VSOutput {
  float4 position [[position]];
  float3 vUv;
};

fragment float4 fragment_main(VSOutput in [[stage_in]]) {
  float3 color = mix(float3(1.0, 0.0, 0.0), float3(0.68, 0.85, 0.9), in.vUv.y);
  return float4(color, 1.0);
}`,
  },
  DirectX: {
    vertex: `struct VSInput {
  float3 position : POSITION;
  float2 uv : TEXCOORD0;
};

struct VSOutput {
  float4 position : SV_POSITION;
  float3 vUv : TEXCOORD0;
};

VSOutput main(VSInput input) {
  VSOutput output;
  output.vUv = float3(input.uv, 1.0);
  output.position = float4(input.position, 1.0);
  return output;
}`,
    fragment: `struct PSInput {
  float3 vUv : TEXCOORD0;
};

float4 main(PSInput input) : SV_TARGET {
  float3 color = lerp(float3(1.0, 0.0, 0.0), float3(0.68, 0.85, 0.9), input.vUv.y);
  return float4(color, 1.0);
}`,
  },
  Crossgl: {
    vertex: `shader main {
  vertex {
    input vec2 uv;
    input vec3 position;
    output vec3 vUv;

    void main() {
      vUv = vec3(uv, 1.0);
      gl_Position = vec4(position, 1.0);
    }
  }`,
    fragment: `shader main {
  fragment {
    input vec3 vUv;
    output vec4 outColor;

    void main() {
      vec3 color = mix(vec3(1.0, 0.0, 0.0), vec3(0.68, 0.85, 0.9), vUv.y);
      outColor = vec4(color, 1.0);
    }
  }
}`,
  },
};
const CustomshaderMaterial = () => {
  const material = useMemo(
    () =>
      new THREE.ShaderMaterial({
        vertexShader: shaders.GLSL.vertex,
        fragmentShader: shaders.GLSL.fragment,
      }),
    []
  );

  return <primitive object={material} attach="material" />;
};
const GradientMaterial = shaderMaterial(
  // Uniforms
  {},
  // Vertex shader
  `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
  // Fragment shader
  `
  varying vec2 vUv;
  void main() {
    vec3 color = mix(vec3(1.0, 0.0, 0.0), vec3(0.68, 0.85, 0.9), vUv.y); // Light blue is RGB(173, 216, 230)
    gl_FragColor = vec4(color, 1.0);
  }
  `
);
extend({ GradientMaterial });
function SpinningBoxWebGL({ scale, scrollState }) {
  const mesh = useRef();
  useFrame((_, delta) => {
    if (scrollState.progress >= 0.4 && scrollState.progress <= 0.6) {
      // Continuously rotate the cube by a small amount each frame

      mesh.current.rotation.y += 0.5 * delta; // Adjust the value for rotation speed
    } else {
      mesh.current.rotation.y = scrollState.progress * Math.PI * 2;
    }
  });

  return (
    <group scale={scale.xy.min() * 0.5}>
      <mesh ref={mesh}>
        <boxGeometry />
        {/* <meshBasicMaterial /> */}

        <gradientMaterial />
      </mesh>
    </group>
  );
}

const AnimatedSection = ({ children, className }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });

  return (
    <motion.div
      ref={ref}
      className={className}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      exit="hidden"
      variants={sectionVariants}
    >
      {children}
    </motion.div>
  );
};
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const borderVariants = {
  hidden: { borderColor: "rgba(107, 114, 128, 0)" },
  visible: {
    borderColor: "rgba(107, 114, 128, 1)",
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
};

const Translator = ({ Translatorref }) => {
  const el = useRef();
  const [selectedLanguage, setSelectedLanguage] = useState("Vulkan");
  const [showLeftPanel, setShowLeftPanel] = useState(false);
  const [showRightPanel, setShowRightPanel] = useState(false);

  const handleLanguageClick = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <motion.div
      ref={Translatorref}
      className="flex flex-col lg:flex-row font-poppins w-full min-h-screen lg:h-screen text-gray-100 items-center justify-center p-4 lg:p-8"
      style={{
        backgroundColor: "#111111",
      }}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Left Panel */}
      <AnimatedSection
        className={`w-full lg:w-1/3 h-auto lg:h-full flex flex-col border border-dotted border-gray-500 code-panel ${
          showLeftPanel ? "block" : "hidden lg:flex"
        }`}
        variants={sectionVariants}
      >
        <motion.div
          className="w-full h-14 flex flex-row border-dotted border border-gray-500 overflow-x-auto"
          variants={borderVariants}
        >
          {["Vulkan", "OpenGL", "Metal", "DirectX"].map((lang) => (
            <motion.div
              key={lang}
              className={`min-w-[25%] border border-dotted border-gray-500 p-2 flex items-center justify-center cursor-pointer ${
                selectedLanguage === lang ? "bg-gray-700" : ""
              }`}
              onClick={() => handleLanguageClick(lang)}
              whileHover={{ backgroundColor: "rgba(75, 85, 99, 0.5)" }}
              variants={borderVariants}
            >
              {lang}
            </motion.div>
          ))}
        </motion.div>
        <ShaderCodeBlock
          title="Vertex shader"
          code={shaders[selectedLanguage].vertex}
        />
        <ShaderCodeBlock
          title="Fragment shader"
          code={shaders[selectedLanguage].fragment}
        />
      </AnimatedSection>

      {/* Center Panel */}
      <AnimatedSection
        className="flex flex-col items-center justify-center w-full lg:w-1/3 h-auto lg:h-full my-4 lg:my-0"
        variants={sectionVariants}
      >
        <motion.div
          className="relative w-full h-1/3 border border-dotted border-gray-500 space-x-3 flex flex-row items-center justify-center p-4"
          variants={borderVariants}
        >
          <div className="absolute inset-0 -z-0 h-full">
            <CurvyBackground
              rotation={[-Math.PI / 3, 0, 0]}
              position={[0, 2, 0]}
              color="#ff2929"
            />
          </div>
          <motion.img
            src="./logo.png"
            className="w-8 sm:w-10 lg:w-12 z-10"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
          <motion.div
            className="text-2xl sm:text-3xl lg:text-4xl z-10"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            Translator
          </motion.div>
        </motion.div>

        <div ref={el} className="Placeholder ScrollScene w-full h-1/3"></div>
        <UseCanvas>
          <ScrollScene track={el}>
            {(props) => <SpinningBoxWebGL {...props} />}
          </ScrollScene>
        </UseCanvas>

        <motion.div
          className="w-full h-1/3 border border-dotted border-gray-500 p-3 sm:p-5 flex flex-col"
          variants={borderVariants}
        >
          <motion.p
            className="text-sm sm:text-base mb-2"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            style={{
              fontSize: "clamp(0.75rem, 1.5vw, 1rem)",
            }}
          >
            Translates native shader languages to CrossGL universal shader
            language and vice versa.
          </motion.p>
          <motion.p
            className="text-sm sm:text-base mb-2"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            style={{
              fontSize: "clamp(0.75rem, 1.5vw, 1rem)",
            }}
          >
            List of supported backends:
          </motion.p>
          <motion.ul
            className="flex-1 overflow-y-auto"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            {["Vulkan", "OpenGL", "Metal", "DirectX"].map((backend) => (
              <li
                key={backend}
                className="mb-1 "
                style={{
                  fontSize: "clamp(0.75rem, 1.5vw, 1rem)",
                }}
              >
                {backend}
              </li>
            ))}
          </motion.ul>
        </motion.div>
      </AnimatedSection>

      {/* Right Panel */}
      <AnimatedSection
        className={`w-full lg:w-1/3 h-auto lg:h-full flex flex-col border border-dotted border-gray-500 code-panel ${
          showRightPanel ? "block" : "hidden lg:flex"
        }`}
        variants={sectionVariants}
      >
        <motion.div
          className="w-full h-14 flex flex-row border-dotted border border-gray-500"
          variants={borderVariants}
        >
          <motion.div
            className="w-full border border-dotted border-gray-500 p-2 flex items-center justify-center cursor-pointer bg-gray-700"
            variants={borderVariants}
          >
            CrossGL
          </motion.div>
        </motion.div>
        <ShaderCodeBlock
          title="Vertex shader"
          code={shaders["Crossgl"].vertex}
        />
        <ShaderCodeBlock
          title="Fragment shader"
          code={shaders["Crossgl"].fragment}
        />
      </AnimatedSection>

      {/* Mobile toggle buttons */}
      <div className="flex w-full justify-between mt-4 lg:hidden">
        <button
          className="bg-gray-700 text-white px-4 py-2 rounded"
          onClick={() => setShowLeftPanel(!showLeftPanel)}
        >
          {showLeftPanel ? "Hide" : "Show"} Input
        </button>
        <button
          className="bg-gray-700 text-white px-4 py-2 rounded"
          onClick={() => setShowRightPanel(!showRightPanel)}
        >
          {showRightPanel ? "Hide" : "Show"} Output
        </button>
      </div>
    </motion.div>
  );
};

// Helper component for shader code blocks
const ShaderCodeBlock = ({ title, code }) => (
  <motion.div
    className="w-full flex-1 overflow-hidden"
    variants={borderVariants}
  >
    <div className="block inset p-1 text-black border border-gray-500 text-xs text-nowrap bg-gray-400 opacity-40">
      {title}
    </div>
    <div className="h-full overflow-auto">
      <SyntaxHighlighter
        language="glsl"
        style={vscDarkPlus}
        customStyle={{
          backgroundColor: "transparent",
          padding: "10px",
          margin: "0",
          height: "100%",
          width: "100%",
          overflow: "auto",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
        wrapLines={true}
        wrapLongLines={true}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  </motion.div>
);

export default Translator;