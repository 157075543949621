import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import blogData from "./BlogData";

const Blogexplorer = () => {
  return (
    <div
      className="relative font-poppins w-full min-h-screen p-4 sm:p-6 md:p-8"
      style={{
        backgroundColor: "#111111",
      }}
    >
      <div className="max-w-6xl mx-auto mt-56">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {blogData.map((blog) => (
            <motion.div
              key={blog.id}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 0.5 }}
            >
              <Link to={`/blog/${blog.id}`} className="block">
                <motion.div
                  className="border border-dotted overflow-hidden shadow-lg transition-shadow duration-300"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="w-full h-48 sm:h-56 border-dotted border-b md:h-64 object-cover"
                  />
                  <div className="p-4">
                    <h2 className="text-sm sm:text-lg font-semibold text-white">
                      {blog.title}
                    </h2>
                  </div>
                </motion.div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogexplorer;
