import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import blogData, { bashInstallCommand, pythonCodeExample } from "./BlogData";

const Blog = () => {
  let { id } = useParams();
  const [blog, setBlog] = useState();

  useEffect(() => {
    const data = blogData.find((blog) => blog.id == id);
    setBlog(data);
  }, [id]);

  const renderContent = (content) => {
    const parts = content.split(/(\[IMAGE:[^\]]+\]|\[VIDEO:[^\]]+\]|\[CODE_BASH_INSTALL\]|\[CODE_PYTHON_EXAMPLE\])/);
    return parts.map((part, index) => {
      if (part.startsWith("[IMAGE:")) {
        const [url, alt] = part.slice(7, -1).split(";");
        return (
          <img
            key={index}
            src={url}
            alt={alt}
            className="w-full my-4 rounded-lg shadow-lg"
          />
        );
      } else if (part === "[CODE_BASH_INSTALL]") {
        return (
          <SyntaxHighlighter key={index} language="bash" style={dracula}>
            {bashInstallCommand.trim()}
          </SyntaxHighlighter>
        );
      } else if (part === "[CODE_PYTHON_EXAMPLE]") {
        return (
          <SyntaxHighlighter key={index} language="python" style={dracula}>
            {pythonCodeExample.trim()}
          </SyntaxHighlighter>
        );
      } else {
        return part.split("\n\n").map((paragraph, pIndex) => (
          <p key={`${index}-${pIndex}`} className="mb-4">
            {paragraph}
          </p>
        ));
      }
    });
  };

  return (
    <div
      className="relative font-poppins w-full min-h-screen p-4 sm:p-6 md:p-8"
      style={{
        backgroundColor: "#111111",
      }}
    >
      <div className="max-w-3xl flex flex-col justify-center mx-auto mt-20">
        {/* Author Information */}
        {blog?.author && (
          <div className="flex items-center mb-6">
            <img
              src={blog.author.image}
              alt={blog.author.name}
              className="w-16 h-16 rounded-full mr-4"
            />
            <div>
              <span className="text-white font-medium text-lg">
                {blog.author.name}
              </span>
              <p className="text-gray-400 text-sm">{blog?.date}</p>
              <p className="text-gray-400 text-sm">{blog?.readTime}</p>
            </div>
          </div>
        )}

        <img
          src={blog?.image}
          alt={blog?.title}
          className="aspect-auto border-dotted border-b mb-6 rounded-lg shadow-lg"
        />
        <h1 className="text-white text-3xl text-center font-bold mb-6">
          {blog?.title}
        </h1>

        <div className="text-white space-y-4">
          {blog?.content && renderContent(blog.content)}
        </div>
      </div>
    </div>
  );
};

export default Blog;

