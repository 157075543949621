import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ scrollToSection, refs }) => {
  const [scrollY, setScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isGraphicaDropdownOpen, setIsGraphicaDropdownOpen] = useState(false);
  const [dropdownTimeout, setDropdownTimeout] = useState(null);
  const [graphicaDropdownTimeout, setGraphicaDropdownTimeout] = useState(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > scrollY) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    setScrollY(currentScrollY);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMouseEnter = () => {
    if (dropdownTimeout) clearTimeout(dropdownTimeout);
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownTimeout(setTimeout(() => setIsDropdownOpen(false), 100));
  };

  const handleGraphicaMouseEnter = () => {
    if (graphicaDropdownTimeout) clearTimeout(graphicaDropdownTimeout);
    setIsGraphicaDropdownOpen(true);
  };

  const handleGraphicaMouseLeave = () => {
    setGraphicaDropdownTimeout(setTimeout(() => setIsGraphicaDropdownOpen(false), 300));
  };

  const handleNavigation = (sectionRef) => {
    navigate("/");
    setTimeout(() => scrollToSection(sectionRef), 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (dropdownTimeout) clearTimeout(dropdownTimeout);
      if (graphicaDropdownTimeout) clearTimeout(graphicaDropdownTimeout);
    };
  }, [scrollY, dropdownTimeout, graphicaDropdownTimeout]);

  return (
    <nav
      className={`fixed top-0 w-full z-50 bg-black text-white p-1 transition-transform ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      }`}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
    >
      <div className="flex justify-between items-center mx-auto px-4 py-2">
        <Link to="/">
          <div className="flex items-center cursor-pointer">
            <div
              className="bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/logo.png)`,
                width: "30px",
                height: "40px",
              }}
            ></div>
            <span className="mx-2 text-2xl font-semibold navbar-logo">
              CrossGL
            </span>
          </div>
        </Link>
        <div className="hidden md:flex items-center space-x-8 navbar-items">
          <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to="#"
              className="inline-flex items-center overflow-hidden hover:text-teal-300"
            >
              PRODUCTS
            </Link>
            {isDropdownOpen && (
              <div
                className="text-center absolute -left-5 right-2 z-10 mt-2 w-32 rounded-md border border-gray-100 bg-white dark:border-teal-600 dark:bg-black"
                role="menu"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="p-2">
                  <Link
                    to="/dyson"
                    className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                    role="menuitem"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    DYSON
                  </Link>
                  <div
                    className="relative"
                    onMouseEnter={handleGraphicaMouseEnter}
                    onMouseLeave={handleGraphicaMouseLeave}
                  >
                    <Link
                      to="#"
                      className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                    >
                      GRAPHICA
                    </Link>
                    {isGraphicaDropdownOpen && (
                      <div className="absolute start-[122px] p-1 top-0 mt-0 w-40 rounded-md border border-gray-100 bg-white dark:border-teal-600 dark:bg-black">
                        <Link
                          to="/crossvl"
                          className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                          role="menuitem"
                        >
                          CrossVL
                        </Link>
                        <Link
                          to="/"
                          onClick={() => {
                            handleNavigation(refs.Translatorref);
                            setIsGraphicaDropdownOpen(false);
                            setIsDropdownOpen(false);
                          }}
                          className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                          role="menuitem"
                        >
                          CrossTL
                        </Link>
                        <Link
                          to="/"
                          onClick={() => {
                            handleNavigation(refs.Compilerref);
                            setIsGraphicaDropdownOpen(false);
                            setIsDropdownOpen(false);
                          }}
                          className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-teal-800 dark:hover:text-teal-300"
                          role="menuitem"
                        >
                          CrossGL-Compiler
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <a
            href="https://github.com/CrossGL"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-teal-300"
          >
            GITHUB
          </a>
          <Link to="/contact" className="hover:text-teal-300">
            CONTACT
          </Link>
        </div>
        <div className="hidden md:flex items-center space-x-6 navbar-items">
          <Link
            to="/blogs"
            className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
          >
            BLOG
          </Link>
          <a
            href="https://crossgl.github.io/index.html"
            target="_blank"
            rel="noopener noreferrer"
            className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
          >
            DOCS
          </a>
        </div>
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`md:hidden flex flex-col items-center space-y-4 my-4 transition-all duration-300 ease-in-out ${
          isMobileMenuOpen
            ? "navbar-items-enter-active"
            : "navbar-items-exit-active navbar-collapsed"
        }`}
      >
        <Link
          to="/dyson"
          onClick={() => {
            setIsMobileMenuOpen(false);
            scrollToSection(refs.Dysonref);
          }}
          className="hover:text-teal-300"
        >
          DYSON
        </Link>
        <Link
          to="/crossvl"
          onClick={() => {
            setIsMobileMenuOpen(false);
          }}
          className="hover:text-teal-300"
        >
          CROSSVL
        </Link>
        <Link
          to="/"
          onClick={() => {
            handleNavigation(refs.Translatorref);
            setIsMobileMenuOpen(false);
          }}
          className="hover:text-teal-300"
        >
          CROSSTL
        </Link>
        <Link
          to="/"
          onClick={() => {
            handleNavigation(refs.Compilerref);
            setIsMobileMenuOpen(false);
          }}
          className="hover:text-teal-300"
        >
          CROSSGL-Compiler
        </Link>
        
        <a
          href="https://github.com/CrossGL"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setIsMobileMenuOpen(false)}
          className="hover:text-teal-300"
        >
          GITHUB
        </a>
        <Link
          to="/contact"
          onClick={() => setIsMobileMenuOpen(false)}
          className="hover:text-teal-300"
        >
          CONTACT
        </Link>
        <Link
          to="/blogs"
          onClick={() => setIsMobileMenuOpen(false)}
          className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
        >
          BLOG
        </Link>
        <a
          href="https://crossgl.github.io/index.html"
          onClick={() => setIsMobileMenuOpen(false)}
          target="_blank"
          rel="noopener noreferrer"
          className="px-5 py-2 border border-teal-600 text-teal-600 rounded hover:border-teal-800 hover:text-teal-300 hover:bg-teal-800"
        >
          DOCS
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
