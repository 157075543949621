import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { GUI } from "lil-gui";
import gsap from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// Note: You'll need to create these shader files
import { motion, useInView } from "framer-motion";
import fragment_reactive from "./shader/fragment_Reactive.js";
import vertex_reactive from "./shader/vertex_Reactive.js";

const ReactiveParticles = ({
  color,
  rotation,
  position,
  debug = false,
  compile = false,
}) => {
  const containerRef = useRef(null);
  const sketchRef = useRef(null);
  const isInView = useInView(containerRef, { once: false, amount: 0.3 });
  const event = document.createEvent("Event");

  // Define that the event name is 'build'.
  event.initEvent("compile", true, true);

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const sketch = new ReactiveParticlesImpl({
      dom: container,

      rotation: rotation,
      position: position,
      color: color,
      debug: debug,
      compile: compile,
    });
    sketchRef.current = sketch;

    return () => {
      if (sketchRef.current) {
        sketchRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      document.dispatchEvent(event);
    }
    // if (compile) {
    //   if (compile) {
    //     document.dispatchEvent(event);
    //     requestAnimationFrame(() => {
    //       sketchRef.current.pointsMesh.rotation.y += 0.005;
    //     });

    //     // gsap.to(sketchRef.current.position, {
    //     //   duration: 0.6,
    //     //   z: THREE.MathUtils.randInt(9, 11), // Random depth positioning within a range
    //     //   ease: "elastic.out(0.8)", // Elastic ease-out for a bouncy effect
    //     // });
    //   }
    // }
  }, [isInView, compile]);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
};

class ReactiveParticlesImpl {
  constructor(options) {
    this.scene = new THREE.Scene();
    this.container = options.dom;
    this.debug = options.debug;
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x111111, 1);
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    this.compile = options.compile;
    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      0.1,
      1000
    );
    this.camera.position.set(0, 0, 2); // Moved camera closer

    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.enableDamping = true;
    // this.controls.enableZoom = false;
    // this.controls.dampingFactor = 0.05;

    document.addEventListener("compile", () => {
      this.compile = true;
    });

    // let axishelper = new THREE.AxesHelper(1);
    // this.scene.add(axishelper);

    this.time = 0;
    this.isPlaying = true;

    this.settings = {
      startColor: options.color,
      endColor: "0x00ffff",
      autoMix: true,
      autoRotate: true,
      meshType: "box",
      size: 0.05, // Reduced particle size
      frequency: 0,
      amplitude: 0.1, // Reduced amplitude
    };

    if (this.debug) {
      this.setupGUI();
    }

    this.addLights();
    this.addObjects();
    this.resize();
    this.setupResize();
    this.render();
  }

  addLights() {
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(5, 5, 5);
    this.scene.add(pointLight);
  }

  setupGUI() {
    this.gui = new GUI();
    this.gui
      .addColor(this.settings, "startColor")
      .onChange(() => this.updateMaterial());
    this.gui
      .addColor(this.settings, "endColor")
      .onChange(() => this.updateMaterial());
    this.gui.add(this.settings, "autoMix");
    this.gui.add(this.settings, "autoRotate");
    this.gui
      .add(this.settings, "meshType", ["box", "cylinder", "sphere"])
      .onChange(() => this.resetMesh());
    this.gui
      .add(this.settings, "size", 0.001, 0.1)
      .onChange(() => this.updateMaterial());
    this.gui
      .add(this.settings, "frequency", 0.1, 5)
      .onChange(() => this.updateMaterial());
    this.gui
      .add(this.settings, "amplitude", 0.01, 1)
      .onChange(() => this.updateMaterial());
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    this.material = new THREE.ShaderMaterial({
      vertexShader: vertex_reactive,
      fragmentShader: fragment_reactive,
      uniforms: {
        time: { value: 0 },
        offsetSize: { value: 2 },
        size: { value: 0.5 },
        frequency: { value: 4 },
        amplitude: { value: 1.4 },
        offsetGain: { value: 0 },
        maxDistance: { value: 2.9 },
        startColor: { value: new THREE.Color(this.settings.startColor) },
        endColor: { value: new THREE.Color(this.settings.endColor) },
      },
      transparent: true,
    });
    // this.material = new THREE.MeshBasicMaterial({ color: "white" });

    this.resetMesh();
  }
  createBoxMesh() {
    // Randomly generate segment counts for width, height, and depth to create varied box geometries
    let widthSeg = Math.floor(THREE.MathUtils.randInt(5, 20));
    let heightSeg = Math.floor(THREE.MathUtils.randInt(1, 40));
    let depthSeg = Math.floor(THREE.MathUtils.randInt(5, 80));
    this.geometry = new THREE.BoxGeometry(
      1,
      1,
      1,
      widthSeg,
      heightSeg,
      depthSeg
    );

    // Update shader material uniform for offset size with a random value
    this.material.uniforms.offsetSize.value = Math.floor(
      THREE.MathUtils.randInt(30, 60)
    );
    this.material.needsUpdate = true;

    // Create a container for the points mesh and set its orientation
    this.pointsMesh = new THREE.Object3D();
    this.pointsMesh.rotateX(Math.PI / 2); // Rotate the mesh for better visual orientation
    // this.holderObjects.add(this.pointsMesh);

    // Create a points mesh using the box geometry and the shader material
    const pointsMesh = new THREE.Points(this.geometry, this.material);
    this.pointsMesh.add(pointsMesh);
    this.scene.add(this.pointsMesh);
    // Animate the rotation of the of the container
  }

  createCylinderMesh() {
    // Randomize radial and height segments for the cylinder geometry
    let radialSeg = Math.floor(THREE.MathUtils.randInt(1, 3));
    let heightSeg = Math.floor(THREE.MathUtils.randInt(1, 5));
    this.geometry = new THREE.CylinderGeometry(
      1,
      1,
      4,
      64 * radialSeg,
      64 * heightSeg,
      true
    );

    // Update shader material uniforms for offset and size with random and fixed values
    this.material.uniforms.offsetSize.value = Math.floor(
      THREE.MathUtils.randInt(30, 60)
    );
    this.material.uniforms.size.value = 2; // Fixed size for uniform appearance
    this.material.needsUpdate = true;
    this.material.uniforms.needsUpdate = true;

    // Create a points mesh using the cylinder geometry and shader material
    this.pointsMesh = new THREE.Points(this.geometry, this.material);
    this.pointsMesh.rotation.set(Math.PI / 2, 0, 0); // Rotate the mesh for better orientation

    this.scene.add(this.pointsMesh);
    let rotY = 0;
    let posZ = THREE.MathUtils.randInt(9, 11);

    if (Math.random() < 0.2) {
      rotY = Math.PI / 2;
      posZ = THREE.MathUtils.randInt(10, 11.5);
    }

    // gsap.to(this.holderObjects.rotation, {
    //   duration: 0.2,
    //   y: rotY,
    //   ease: "elastic.out(0.2)",
    // });

    gsap.to(this.position, {
      duration: 0.6,
      z: posZ,
      ease: "elastic.out(0.8)",
    });
  }

  createSphereMesh() {
    // Randomly generate segment counts for width and height to create varied sphere geometries
    let widthSeg = Math.floor(THREE.MathUtils.randInt(10, 40));
    let heightSeg = Math.floor(THREE.MathUtils.randInt(10, 40));
    this.geometry = new THREE.SphereGeometry(
      0.5, // radius
      widthSeg,
      heightSeg
    );

    // Update shader material uniform for offset size with a random value
    this.material.uniforms.offsetSize.value = Math.floor(
      THREE.MathUtils.randInt(30, 60)
    );
    this.material.needsUpdate = true;

    // Create a container for the points mesh
    this.pointsMesh = new THREE.Object3D();
    // this.holderObjects.add(this.pointsMesh);

    // Create a points mesh using the sphere geometry and the shader material
    const pointsMesh = new THREE.Points(this.geometry, this.material);
    this.pointsMesh.add(pointsMesh);
    this.scene.add(this.pointsMesh);

    // // Animate the rotation of the container
    // gsap.to(this.pointsMesh.rotation, {
    //   duration: 3,
    //   x: Math.random() * Math.PI * 2,
    //   y: Math.random() * Math.PI * 2,
    //   z: Math.random() * Math.PI * 2,
    //   ease: "none", // No easing for a linear animation
    // });

    // gsap.to(this.position, {
    //   duration: 0.6,
    //   z: THREE.MathUtils.randInt(9, 11), // Random depth positioning within a range
    //   ease: "elastic.out(0.8)", // Elastic ease-out for a bouncy effect
    // });
  }

  resetMesh(meshname) {
    if (this.pointsMesh) {
      this.scene.remove(this.pointsMesh);
    }

    let geometry;

    switch (this.settings.meshType) {
      case "box":
        this.createBoxMesh();
        break;
      case "cylinder":
        this.createCylinderMesh();
        break;
      case "sphere":
        this.createSphereMesh();
        break;
    }

    // this.pointsMesh = new THREE.Points(geometry, this.material);
    // this.scene.add(this.pointsMesh);
    // console.log(this.pointsMesh);
    // gsap.to(this.pointsMesh.rotation, {
    //   duration: 3,
    //   x: Math.random() * Math.PI,
    //   y: Math.random() * Math.PI * 2,
    //   z: Math.random() * Math.PI,
    //   ease: "none",
    // });
  }

  updateMaterial() {
    this.material.uniforms.startColor.value = new THREE.Color(
      this.settings.startColor
    );
    this.material.uniforms.endColor.value = new THREE.Color(
      this.settings.endColor
    );
    this.material.uniforms.size.value = this.settings.size;
    this.material.uniforms.frequency.value = this.settings.frequency;
    this.material.uniforms.amplitude.value = this.settings.amplitude;
  }

  render() {
    if (!this.isPlaying) return;

    this.time += 0.05;
    this.material.uniforms.time.value = this.time;

    if (this.compile) {
      gsap.to(this.pointsMesh.rotation, {
        duration: 3,
        z: Math.PI * 4,
        ease: "power2.out",
      });

      gsap
        .to(this.material.uniforms.amplitude, {
          duration: 1, // Adjust duration as needed
          value: 2.5, // Target amplitude value
          ease: "power2.out", // Choose an appropriate easing function
        })
        .then(() => {
          gsap.to(this.material.uniforms.amplitude, {
            duration: 1, // Adjust duration as needed
            value: 1, // Back to original amplitude
            ease: "power2.in", // Choose an appropriate easing function
          });
        });

      gsap.to(this.material.uniforms.maxDistance, {
        duration: 1.8, // Adjust duration as needed
        value: 4, // Target amplitude value
        ease: "power2.out", // Choose an appropriate easing function
      });
      // .then(() => {
      //   gsap.to(this.material.uniforms.maxDistance, {
      //     duration: 1, // Adjust duration as needed
      //     value: 4, // Back to original amplitude
      //     ease: "power2.in", // Choose an appropriate easing function
      //   });
      // });

      gsap
        .to(this.material.uniforms.size, {
          duration: 1.8, // Adjust duration as needed
          value: 0.5, // Target amplitude value
          ease: "power2.out", // Choose an appropriate easing function
        })
        .then(() => {
          gsap.to(this.material.uniforms.size, {
            duration: 1, // Adjust duration as needed
            value: 1.8, // Back to original amplitude
            ease: "power2.in", // Choose an appropriate easing function
          });
        });
      gsap.to(this.camera.position, {
        duration: 2,
        z: 2,
      });
      this.compile = false;
    }
    // this.pointsMesh.rotation.z += 0.005;
    // if (this.settings.autoMix && Math.random() < 0.001) {

    //   this.resetMesh("cylinder");
    // }
    // this.controls.update();
    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }

  destroy() {
    this.isPlaying = false;
    this.renderer.dispose();
    if (this.debug) {
      this.gui.destroy();
    }
    if (this.controls) {
      this.controls.dispose();
    }
    window.removeEventListener("resize", this.resize);
    while (this.container.firstChild) {
      this.container.removeChild(this.container.firstChild);
    }
  }
}

export default ReactiveParticles;
